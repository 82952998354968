@import "../../../styles/variables.scss";

.NavbarWrapper_Scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  z-index: 1200;
  left: auto;
  right: 0px;
  outline: none;
  border: none;
  top: 0;
  background-color: rgba(255, 255, 255, 0.72);
  backdrop-filter: saturate(180%) blur(20px);
  border-bottom: 1px solid #E4E7EC;
}

.NavbarWrapper {
  display: flex;
  transition: .2s;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  z-index: 1200;
  left: auto;
  right: 0px;
  outline: none;
  border: none;
  top: 0;
  background-color: transparent;
}

.NavbarWrapper_Dark {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  transition: .2s;
  flex-shrink: 0;
  position: fixed;
  z-index: 1200;
  left: auto;
  right: 0px;
  color: rgb(255, 255, 255);
  outline: none;
  border: none;
  background-color: rgb(26, 32, 44);
  top: 0;
}

.NavbarWrapper_DarkScroll {
  transition: .2s;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(0 0 0 / 25%) 0px 3px 6px 0px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  background-color: rgb(26, 32, 44);
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: sticky;
  z-index: 1200;
  left: auto;
  right: 0px;
  color: rgb(255, 255, 255);
  outline: none;
  border: none;
  top: 0;
}

.NavbarContainer_Light {
  background-color: transparent;
  padding: 0 1rem 1.5rem 0.5rem;
  transition: .2s;
  color: #000;
}

.NavbarRow {
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding-top: 1rem;

  @media (min-width: 900px) {
    max-width: 1310px;
  }
}

.NavbarRow_Dark {
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  background-color: rgb(26, 32, 44);
  transition: .2s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding-top: 1rem;

  @media (min-width: 900px) {
    max-width: 1236px;
  }
}

.NavLogo {
  font-size: 20px;
}

.icons-col {
  margin: auto;
  display: block;
}

.NavItems_Column {
  display: block;

  ul {
    li {
      float: left;
      list-style-type: none;
      // padding: 0px 0.8em 0 0.8em;
    }
  }
}

a {
  text-decoration: none;
  color: #333;
}

.BurgerNav {
  display: none;

  @include mobile(999px) {
    display: block;
  }
}

// Display theme buttons
.DarkModeBtn {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 30px;
  padding: 0.3rem;
  cursor: pointer;
}

.LightModeBtn {
  background-color: transparent;
  border-radius: 4px;
  height: 30px;
  padding: 0.3rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}