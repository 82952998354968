.BodyContent {
  p {
    margin-top: 20px;
    line-height: 2rem;
    font-weight: 300;
    letter-spacing: -0.003em;
  }
  strong {
    span {
      font-weight: bold !important;
    }
  }
  span {
    line-height: 2rem;
    font-weight: 300 !important;
    color: #292929 !important;
    letter-spacing: -0.003em;
  }

  ul {
    margin-left: 5rem !important;
  }
}