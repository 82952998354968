.DefaultPosition {
  opacity: 0;
  transition: .5s;
  -webkit-transform: translate3d(0,50px,0);
}

.FixedPosition {
  opacity: 1;
  transition: .5s;
  -webkit-transform: translate3d(0,0px,0);
}

.FadeUp {
  position: relative;
  opacity: 1;
  -webkit-transform: none;
  transition-duration: .5s;
  transition-timing-function: ease-in;
  -webkit-transform: translate3d(0,0px,0);
}