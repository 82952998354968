.MobileNavigation {
  width: 260px;
  z-index: 999;
  height: 100vh;
  background-color: #FFF;
  box-shadow: rgb(140 152 164 / 25%) 0px 3px 6px 0px;
  position: fixed;
  top: 0;
  padding: 2rem;
}

.NavLogo {
  font-size: 20px;
}