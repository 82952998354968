@import "./variables.scss";
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@700&display=swap');

// @font-face {
//   font-family: 'Helvetica neue';
//   src: url('../assets/fonts/helveticaneue/HelveticaNeue\ Light.ttf') format('truetype');
// }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

// chatlist
.minimize {
  transition: 0.1s;
  height: 50px !important;
}

.paypal-button-text {
  display: none !important;
  visibility: hidden !important;
}

.IconBtn {
  background-color: transparent;
  border: 0px solid transparent;
  cursor: pointer;
}

.HideMobile {
  display: block;
  @include mobile(999px) {
    display: none !important;
  }
}

.user-dropdown {
  background-color: #FFF;
  width: 300px;
  padding: 0;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0 1px 9px #e8e8e8 !important;
}


// OVERRIDES
fieldset.MuiOutlinedInput-notchedOutline, textarea {
  border-color:#d9d9d9 !important;
}

.css-18lrjg1-MuiCircularProgress-root {
  color: #FFF;
}

// BUTTONS
.primaryButton {
  // background-color: #000;
  color: #FFF;
  border: 0px;
  padding: 0.6rem 1.8rem;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
}

.secondaryButton {
  background-color: transparent;
  color: #333;
  font-weight: 600;
  padding: 0.6rem;
  border: 1px solid #000;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.clearButton {
  background-color: transparent;
  color: #333;
  font-weight: 600;
  padding: 0.6rem;
  border-color: transparent;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.NotifIcon {
  background-color: #FA383E;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12.5px;
  text-align: center;
  color: #FFF;
  overflow: hidden;

  span {
    margin-top: -2px;
  }
}

// TEXTAREA OVERRIDE
textarea {
  padding: 1rem;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-size: 14px;

  &::placeholder {
    font-weight: 400;
    opacity: 0.6;
  }
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 0px !important;
}

.v-application ul, .v-application ol {
  padding-left: 0px !important;
}

.primary-color {
  color: $crimson;
}

.disable-color {
  color: #D9D9DA;
}

.v-application p {
  margin-bottom: 0 !important;
}

.answer-body, .question-body {
  img {
    width: 90% !important;
  }
}

.v-list-item {
  min-height: 30px !important;
}

// overlay
.v-overlay__scrim {
  background-color: rgba(36,36,36,1);
}

// modal dialog styles
.v-dialog, .v-menu__content {
  box-shadow: 1px 0 4px rgb(0 2 4 / 6%), 0 7px 18px rgb(1 1 1 / 8%) !important;
}

// snackbar alert
.v-snack__wrapper.theme--dark {
  background-color: #FFF !important;
  color: #333 !important;
}

// success snackbar
.success-snackbar {
  .v-snack__wrapper.v-sheet.theme--dark {
    border-left: 5px solid #3cc44b !important;
  }
}

// snackbar shadow
.v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
  border: 0.5px solid rgba(118, 118, 118, 0.28) !important;
  border-radius: 12px !important;
  box-shadow: rgb(0 0 0 / 15%) 0px 10px 37px !important;
}

// error snackbar
.error-snackbar {
  .v-snack__wrapper.v-sheet.theme--dark {
    border-left: 5px solid #d81e06 !important;
  }
}

// special classes
.center {
  margin: auto !important;
}

.gradient-text {
  -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(
    -70deg
    ,#ff872c,#f11d28);
    -webkit-background-clip: text;
    background-clip: text;
}

// FLEX classes
.FlexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.FlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FlexNowrap {
  display: flex;
  flex-wrap: nowrap;
}

.FlexWrap {
  display: flex;
  flex-wrap: wrap;
}

.mobile-flex-column {
  @include mobile(999px) {
    flex-direction: column;
  }
}

// texts
.uppercase {
  text-transform: uppercase;
  font-weight: 800;
}


// TEXT and FONTS
.bold {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.line-through {
  text-decoration: line-through;
}

.heading-h1 {
  font-size: 2rem;
  font-weight: 700;
}

.Heading1 {
  font-size: 2rem;
  font-weight: 700;

  @include mobile(999px) {
    font-size: 1.7rem;
  }
}

.text-error {
  color: #d81e06;
}

.TextCenter {
  text-align: center;
}

.border-top {
  border-top: 1px solid #eee;
}

.border-right {
  border-right: 1px solid #eee;
}

.border-left {
  border-left: 1px solid #eee;
}

.Ellipsis {
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: .90rem;
}

.Ellipsis-2 {
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: .90rem;
}

.Ellipsis-3 {
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: .90rem;
}

// MATERIAL UI
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #333 !important;
  border-width: 1px !important;
}

// MATERIAL UI MODALS
.MuiBox-root.css-1wnsr1i {
  width: 60% !important;
  border: 0px solid transparent !important;
}

// MATERIAL UI AUTOCOMPLETE
fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid #d9d9d9 !important;
}

.css-hsnmye-MuiAutocomplete-root, .MuiOutlinedInput-root {
  padding: 3px 9px 3px 3px !important;
}


// texts
.Heading_H1 {
  font-size: 1.3rem;
  letter-spacing: 0;
}

.Heading_H2 {
  font-size: 1rem;
  letter-spacing: 0;
}

.Heading_H3 {
  font-size: 0.875rem;
  letter-spacing: 0;
}

.TextSize_12 {
  font-size: 0.75rem;
  letter-spacing: 0;
}

.TextSize_14 {
  font-size: 0.875rem;
  letter-spacing: 0;
}

.TextSize_16 {
  font-size: 1rem;
  letter-spacing: 0;
}

.TextSize_18 {
  font-size: 1.1rem;
  letter-spacing: 0;
  font-weight: 400;
}