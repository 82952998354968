.dark-primary {
  background-color: rgb(26, 32, 44);
  transition: .2s;
  color: #FFF;

  a {
    color: #FFF;
  }

  button {
    background-color: #4C64FE;
    color: #FFF;
  }
}

.dark-secondary {
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  background-color: rgb(26, 32, 44);
  transition: .2s;
  border-radius: 8px;
  color: #FFF;
}

.dark-secondary-shadow {
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  background-color: rgb(26, 32, 44);
  transition: .2s;
  color: #FFF;
  box-shadow: rgb(0 0 0 / 25%) 0px 3px 6px 0px;

  a {
    color: #FFF;
  }

  div {
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    background-color: rgb(26, 32, 44);
    color: #FFF;
  }
}

.dark-secondary-permanent {
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  background-color: rgb(26, 32, 44);
  transition: .2s;
  color: #FFF;

  a {
    color: #FFF;
  }
}

.light-primary {
  transition: .2s;
  border-radius: 8px;
  color: #303133;
}

.light-secondary {
  background-color: #fafafa;
  transition: .2s;
  color: #303133;
}

.light-tertiary {
  background-color: #FFF;
  transition: .2s;
  color: #303133;
}

.light-secondary-shadow {
  background-color: #FFF;
  transition: .2s;
  color: #303133;
  box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 8px;
}

.transparent {
  background-color: transparent;
  transition: .2s;
}