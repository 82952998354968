.FooterWrapper {
  width: 1140px;
  padding: 2rem 0;

  .LowerBrand {
    margin-top: 5rem;
    text-align: center;
  }

  .Links {
    padding-top: 0.5rem;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}